import { v4 as uuid } from 'uuid';
import { db } from '../../firebase/config';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';

import styles from './Hotels.module.css';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const { timestampLongUTCISO } = require('../../common/iso_dates');

function AddHotel({ state, stateSubstitute, stateAdd }) {
  let adding_name;
  let adding_note;
  let adding_stripe_account;
  let adding_scapalay_account;
  let adding_pagolight_merchant_key;
  let adding_pagolight_is_pro;

  const MySwal = withReactContent(Swal);

  async function addHotel() {
    let private_key = uuid();
    let timestamp = timestampLongUTCISO();

    try {
      addDoc(collection(db, 'hotels'), {
        name: adding_name,
        private_key,
        note: adding_note,
        valid: true,
        stripe_account: adding_stripe_account,
        scalapay_key: adding_scapalay_account,
        pagolight_credentials: {
          is_pro: adding_pagolight_is_pro ? true : false,
          merchant_key: adding_pagolight_merchant_key,
        },
        timestamp,
      }).then((responde) => {
        stateAdd('hotels', {
          key: responde.id,
          value: {
            name: adding_name,
            private_key,
            note: adding_note,
            valid: true,
            stripe_account: adding_stripe_account,
            scalapay_key: adding_scapalay_account,
            pagolight_credentials: {
              is_pro: adding_pagolight_is_pro ? true : false,
              merchant_key: adding_pagolight_merchant_key,
            },
            timestamp,
          },
        });
      });
    } catch (error) {
      throw new Error(error);
    }
  }

  function fireSwalToAddHotel() {
    MySwal.fire({
      html: (
        <div>
          <h2>Aggiungi Hotel</h2>
          <form className={styles.form}>
            <div>
              <label>Nome</label>
              <input type='text' id='name' />
              <p className={styles.error} id='error_name'></p>
            </div>
            <div>
              <label>Note</label>
              <textarea id='note' />
            </div>
            <div>
              <label>Account Stripe</label>
              <input type='text' id='stripe_account' />
            </div>
            <div style={{display: 'none'}}>
              <label>Account Scalapay</label>
              <input type='text' id='scalapay_key' />
            </div>
            <hr/>
            <h3 className={styles['no-margin-bottom']}>Account Pagolight</h3>
            <div className={styles.checkbox}>
              <input type="checkbox" id="pagolight_is_pro" />&nbsp;Pagolight PRO
            </div>
            <div>
              <label>Merchant Id</label>
              <input type='text' id='pagolight_merchant_key' />
            </div>
          </form>
        </div>
      ),
      confirmButtonText: 'Conferma',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: 'Annulla',
      preConfirm: async () => {
        adding_name = MySwal.getPopup().querySelector('#name').value;
        adding_note = MySwal.getPopup().querySelector('#note').value;
        adding_stripe_account = MySwal.getPopup().querySelector('#stripe_account').value;
        adding_scapalay_account = MySwal.getPopup().querySelector('#scalapay_key').value;
        adding_pagolight_merchant_key = MySwal.getPopup().querySelector('#pagolight_merchant_key').value;
        adding_pagolight_is_pro = MySwal.getPopup().querySelector('#pagolight_is_pro').checked;

        let error_name = MySwal.getPopup().querySelector('#error_name');

        // reset the error text
        error_name.innerHTML = '';
        MySwal.getPopup().querySelector('#name').classList = '';

        if (!adding_name) {
          error_name.innerHTML = 'Campo obbligatorio';
          MySwal.getPopup().querySelector('#name').classList = 'error';
          return false;
        }

        let hotels_names = await getDocs(query(collection(db, 'hotels'), where('name', '==', adding_name)));
        if (hotels_names.size) {
          MySwal.getPopup().querySelector('#name').classList = 'error';
          error_name.innerHTML = 'Nome già esistente';
          return false;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        addHotel().then(
          MySwal.fire({
            icon: 'success',
            title: 'Operazione Confermata',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          })
        ).catch((error) =>{
          console.error(error);
        });
      }
    });
  }

  return (
    <button onClick={fireSwalToAddHotel} className={styles['btn-add']}>
      <img src={'/svg/plus.svg'} style={{ width: '2.15em' }} alt='' /> Aggiungi Hotel
    </button>
  );
}

export default AddHotel;
