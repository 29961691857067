import HotelList from './HotelList';

import styles from './Hotels.module.css';
import AddHotel from './AddHotel';

export function fromRefsToIds(collection_data) {
  let keys_of_collection_data = Object.keys(collection_data);
  let keys_ending_with_ref = keys_of_collection_data.filter((key) => key.endsWith('_ref'));

  keys_ending_with_ref.forEach((key_with_ref) => {
    let key_with_id = key_with_ref.replace(/_ref$/, '_id');

    // errore se key_with_id esiste gia' e se 'id' non esiste in collection_data[key_with_ref] (per ora log)
    if (key_with_id in collection_data) console.error(`ERROR: ${key_with_id} already present in collection_data`);
    if (!('id' in collection_data[key_with_ref])) console.error(`ERROR: id not present in collection_data[${key_with_ref}]`);

    collection_data[key_with_id] = collection_data[key_with_ref].id;
    delete collection_data[key_with_ref];
  });

  return collection_data;
}

function Hotels({ state, stateSubstitute, stateAdd, SHORT_KEY_LENGTH, MAX_TABLE_ROWS_FOR_PAGE }) {
  const hotels_filterd = Object.entries(state.hotels)
    .filter(([hotel_id, hotel]) => hotelMatchesSearch(hotel_id, hotel));

  const hotels_sorted = hotels_filterd.sort((
      [first_hotel_id, { ...first_hotel_value }],
      [second_hotel_id, { ...second_hotel_value }]
    ) => !first_hotel_value.timestamp ? -1 : !second_hotel_value.timestamp ? 1 : second_hotel_value.timestamp.localeCompare(first_hotel_value.timestamp) )

  const hotels_sliced = hotels_sorted.slice(0, MAX_TABLE_ROWS_FOR_PAGE);

  // this function is used to make a filter based on the content of state.search.hotels
  function hotelMatchesSearch(hotel_id, hotel) { //ANDREI-OK
    let search = state.search.hotels;

    // state.search.hotels. is true when the user is searching something, so we are looking for a match in the state in that moment
    if (
      (search.id && (!hotel_id || !hotel_id.toLowerCase().includes(search.id.toLowerCase()))) ||
      (search.hotel_name &&
        (!hotel.name || !hotel.name.toLowerCase().includes(search.hotel_name.toLowerCase()))) ||
      (search.stripe_account &&
        (!hotel.stripe_account ||
          !hotel.stripe_account.toLowerCase().includes(search.stripe_account.toLowerCase()))) ||
      (search.scalapay_key &&
        (!hotel.scalapay_key ||
          !hotel.scalapay_key.toLowerCase().includes(search.scalapay_key.toLowerCase()))) ||
      (search.klarna_key &&
        (!hotel.klarna_credentials?.active ||
          !hotel.klarna_credentials.username.toLowerCase().includes(search.klarna_key.toLowerCase()))) ||
      (search.hotel_private_key &&
        (!hotel.private_key ||
          !hotel.private_key.toLowerCase().includes(search.hotel_private_key.toLowerCase()))) ||
      (search.pagolight_key &&
        (!hotel.pagolight_credentials?.active ||
          !hotel.pagolight_credentials.merchant_key.toLowerCase().includes(search.pagolight_key.toLowerCase()))) ||
      (search.note &&
        (!hotel.note || !hotel.note.toLowerCase().includes(search.note.toLowerCase()))) ||
      !checkHotelValid(hotel, search)
    ) {
      return false;
    }

    return true;
  }

  // this function is used to select the hotels, based on the content of search.valid
  // and the hotel itself (if valid or not)
  function checkHotelValid(hotel, search) /*hotel = state.hotels[id] */ {
    if (search.valid === 'all') {
      return true;
    } else {
      if (hotel.valid) {
        if (search.valid === 'valid') {
          return true;
        } else {
          return false;
        }
      } else {
        if (search.valid === 'invalid') {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  return (
    <>
      <h1>Hotel</h1>
      <AddHotel state={state} stateAdd={stateAdd} stateSubstitute={stateSubstitute} />
      <div className={`${styles.container} ${styles.title}`}>
        <p className={styles.item}>ID Hotel</p>
        <p className={styles.item}>Chiave Privata Holipay</p>
        <p className={styles.item}>Nome</p>
        <p className={styles.item}>Note</p>
        <p className={styles.item}>Account Stripe</p>
        <p className={styles.item}>Account Scalapay</p>
        <p className={styles.item}>Account Klarna</p>
        <p className={styles.item}>Account Pagolight</p>
        <p className={styles.item}>Attivo</p>
        <p className={styles.item}>Prenotazioni</p>
        <p className={styles.item}>Modifica</p>
      </div>
      <div className={`${styles.container} ${styles.filter}`}>
        <input
          className={styles.item}
          placeholder='Cerca per id'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:id', e.target.value);
          }}
          value={state.search.hotels.id}
        />
        <input
          className={styles.item}
          placeholder='Cerca per chiave privata'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:hotel_private_key', e.target.value);
          }}
          value={state.search.hotels.private_key}
        />
        <input
          className={styles.item}
          placeholder='Cerca per nome'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:hotel_name', e.target.value);
          }}
          value={state.search.hotels.hotel_name}
        />
        <input
          className={styles.item}
          placeholder='Cerca per note'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:note', e.target.value);
          }}
          value={state.search.hotels.note}
        />
        <input
          className={styles.item}
          placeholder='Cerca per chiave account stripe'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:stripe_account', e.target.value);
          }}
          value={state.search.hotels.stripe_account}
        />
        <input
          className={styles.item}
          placeholder='Cerca per account scalapay'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:scalapay_key', e.target.value);
          }}
          value={state.search.hotels.scalapay_key}
        />
        <input
          className={styles.item}
          placeholder='Cerca per account klarna'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:klarna_key', e.target.value);
          }}
          value={state.search.hotels.klarna_key}
        />
        <input
          className={styles.item}
          placeholder='Cerca per account pagolight'
          type='text'
          onChange={(e) => {
            stateSubstitute('search:hotels:pagolight_key', e.target.value);
          }}
          value={state.search.hotels.pagolight_key}
        />
        <select
          className={styles.item}
          onChange={(e) => {
            stateSubstitute('search:hotels:valid', e.target.value);
          }}
          value={state.search.hotels.valid}>
          <option value='all'></option>
          <option value='valid'>Sì</option>
          <option value='invalid'>No</option>
        </select>
      </div>
      {hotels_sliced.length ? (
        <>
          {hotels_sliced.map(([id]) => (
            <HotelList
              key={id}
              id={id}
              state={state}
              stateSubstitute={stateSubstitute}
              SHORT_KEY_LENGTH={SHORT_KEY_LENGTH}
            />
          ))}
        </>
      ) : (
        <p>Nessun Hotel da visualizzare</p>
      )}
    </>
  );
}

export default Hotels;
